import React, { useEffect } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { PiCookieFill } from "react-icons/pi";
import { Link } from "react-router-dom";


const CookieModal = () => {
  const style = {
    position: "absolute",
    top: {lg:"75%",md:'75%', sm:'70%', xs:'70%'},
    left: {lg:"80%",md:'80%', sm:'50%', xs:'50%'},
    transform: "translate(-50%, -50%)",
    width: {lg:500,md:500, sm:350, xs:320},
    bgcolor: "background.paper",
    boxShadow: 24,
    boxSizing: "border-box",
    borderRadius: "8px",

  };

  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  useEffect(()=>{
    const isCookies = localStorage.getItem("reisty_cookies")
    if(isCookies){
      handleClose()
    }else{
      handleOpen()
    }
  }, [])
  const handleCookies = () =>{
    localStorage.setItem("reisty_cookies", true)
    handleClose()
  }
  return (
    <>
      <Modal
        // hideBackdrop
        disablePortal
        disableScrollLock={true}
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
           sx={{
        "&:focus-visible": {
          outline: "none",  // Remove the default focus outline
        },
      }}
      >
        <Box sx={style}>
          <Box align="center" sx={{}}>
            <PiCookieFill
              style={{ fontSize: "40px", marginTop: "-20px", color: "#bc172f" }}
            />
          </Box>
          <Box
            sx={{
              px: 3,
              fontWeight: 400,
              fontSize: "12px",
              textAlign: "justify",
              color: "#333",
            }}
          >
       We take your privacy seriously and only process your personal information to make your experience better while
you use this website. Following Nigeria Data Protection Act, 2023 and any applicable regulations, continuing to
use this platform indicates your consent to the processing of your data by Reisty, its subsidiaries and partners as
detailed in our        <Link to="/privacy-policy" style={{fontWeight:500, textDecoration:'underline', color:'#BC172F'}}>Privacy Policy</Link><br/><br/>

Our site also uses cookies to enhance your experience while you are here. You can modify your preference
using the option below. For more detailed information about the cookies we use, see our   <Link to="/cookie-policy" style={{fontWeight:500, textDecoration:'underline', color:'#BC172F'}}>Cookie Policy</Link>
          </Box>
          <Box
            sx={{
              margin:'0 auto',
              mt: 3,
              px: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
              width:{lg:"60%"},

            }}
          >
     
       
            <Button
            onClick={handleCookies}
              variant="outlined"
              sx={{ fontSize: "10px", borderRadius: "6px", width: "80px" }}
            >
 Reject All
            </Button>
            <Button
            onClick={handleCookies}
              variant="contained"
              sx={{ fontSize: "10px", borderRadius: "6px", width: "80px" }}
            >
           Accept All
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CookieModal;
