// Import necessary modules and utilities
import axios from "axios";
// import { LocalStorage } from "../utils";

const apiKey = process.env.REACT_APP_API_KEY;

const baseUrl = process.env.REACT_APP_BASE_URL;

// Create an Axios instance for API requests
const apiClient = axios.create({
  baseURL: baseUrl,
  timeout: 120000,
});

apiClient.interceptors.request.use(
  function (config) {
    // Retrieve user token from local storage
    const userToken = JSON.parse(window.localStorage.getItem("userInfo"));

    // Set authorization header with bearer token
    config.headers.Authorization = `Bearer ${userToken?.access_token}`;
    config.headers.apiKey = apiKey;
    // config.headers["Access-Control-Allow-Origin"] = "*";

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiClient.interceptors.request.use((config) => {
  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});

// API functions for different actions

// AUTH ......
const loginUser = (emailAddress, password) => {
  const payload = {
    emailAddress,
    password,
  };
  return apiClient.post("/auth/login", payload);
};
const loginGoogle = (email) => {
  return apiClient.post("/auth/google/login", { email });
};

const createUser = (
  emailAddress,
  phoneNumber,
  password,
  confirmPassword,
  firebaseToken,
  firstName,
  lastName,
  country
) => {
  const payload = {
    emailAddress,
    phoneNumber,
    password,
    confirmPassword,
    firebaseToken,
    firstName,
    lastName,
    country,
  };
  return apiClient.post("/auth/create_user", payload);
};

const verifiyOtp = (token, sender) => {
  const payload = {
    token,
    sender,
    isMobileGuest: false,
  };

  return apiClient.post("/auth/verify_onetimecode", payload);
};

const resendOtp = (email) => {
  return apiClient.get(`/auth/send_onetimecode?email=${email}`);
};

// PROFILE.......

const getUserProfile = () => {
  return apiClient.get("userprofile/get_user_details");
};

const editUserProfile = (
  firstName,
  lastName,
  phoneNumber,
  birthday,
  anniversary,
  bio,
  dietaryNote
) => {
  const payload = {
    firstName,
    lastName,
    phoneNumber,
    birthday,
    anniversary,
    bio,
    dietaryNote,
    favouriteCities: "",
    firebaseToken: "",
  };
  return apiClient.put(`userprofile/update_user_profile`, payload);
};

const uploadUserImage = (ImageUpload) => {
  const formData = new FormData();
  formData.append("ImageUpload", ImageUpload);
  return apiClient.post("userprofile/update_profile_photo", formData);
};

const changePassword = (currentPassword, newPassword, confirmPassword) => {
  const payload = {
    currentPassword,
    newPassword,
    confirmPassword,
  };
  return apiClient.put(`auth/change_password`, payload);
};
const getWallet = () => {
  return apiClient.get("payment/wallet/current_user");
};
const getTransactions = () => {
  return apiClient.get("payment/transactions");
};

// RESTAURANTS.......

const getAllFeaturedRestaurants = () => {
  return apiClient.get(`guestservice/get_all_featured_restaurant`);
};

const getBestRestaurants = () => {
  return apiClient.get(`guestservice/get_all_best_restaurants_in_africa`);
};

const addToFavourite = (id) => {
  return apiClient.post(`guestService/favorite_restaurant/${id}`);
};

const getSingleRestaurant = (id) => {
  return apiClient.get(`guestservice/restaurant/${id}`);
};

const getOpeningHours = (RestaurantId, Date, Size, FloorplanId) => {
  return apiClient.get(
    `reservation/guest/restaurant_opening_hour?RestaurantId=${RestaurantId}&Date=${Date}&Size=${Size} ${FloorplanId?.length > 1 ?  `&FloorplanId=${FloorplanId}` : ""}`
  );
};

const findRestaurant = (Text) => {
  return apiClient.get(`/guestservice/search_restaurants?Text=${Text}`);
};
const filterRestaurant = (
  Text,
  Longitute,
  Latitude,
  ReservationTime,
  ReservationDay,
  partySize
) => {
  return apiClient.get(
    `/guestservice/search_restaurants?Text=${Text}&Longitute=${Longitute}&Latitude=${Latitude}&ReservationTime=${ReservationTime}&ReservationDay=${ReservationDay}&PartySize=${partySize}`
  );
};

const createReservation = (
  restaurantId,
  floorplanId,
  date,
  time,
  guestNum,
  phoneNumber,
  amount,
  payStackTransactionId,
  guests,
  occassion,
  Dietary,
  specialRequest,
  EmailsAndOffers,
  NewsAndUpdates,
  useWithWalletBalance,
  noPayment
) => {
  const payload = {
    restaurantId: restaurantId,
    floorplanId,
    date: date,
    time: time,
    guestNum: guestNum,
    wantToGetEmailsAndOffersNotification: EmailsAndOffers,
    wantToGetNewsAndUpdates: NewsAndUpdates,
    guests: guests,
      reservationSource:"Website",
    planGuestVisit: {
      occasionCelebrating: occassion,
      groupMemberDietaryRestriction: Dietary,
      specialRequest: specialRequest,
    },
    bookingPayment: {
      amount: amount,
      paymentMethod:noPayment ? "NoPayment" : "Card",
      payStackTransactionId: payStackTransactionId,
    },
    useWithWalletBalance: useWithWalletBalance,
    guestPhoneNumber: phoneNumber,
  };

  return apiClient.post("/reservation/guest/book", payload);
};
const createReservationDiner = (
  restaurantId,

  date,
  time,
  guestNum,
  phoneNumber,
  amount,
  payStackTransactionId,
  guests,
  occassion,
  Dietary,
  specialRequest,
  EmailsAndOffers,
  NewsAndUpdates,
  useWithWalletBalance,
  noPayment
) => {
  const payload = {
    restaurantId: restaurantId,

    date: date,
    time: time,
    guestNum: guestNum,
    wantToGetEmailsAndOffersNotification: EmailsAndOffers,
    wantToGetNewsAndUpdates: NewsAndUpdates,
    guests: guests,
      reservationSource:"Website",
    planGuestVisit: {
      occasionCelebrating: occassion,
      groupMemberDietaryRestriction: Dietary,
      specialRequest: specialRequest,
    },
    bookingPayment: {
      amount: amount,
      paymentMethod:noPayment ? "NoPayment" : "Card",
      payStackTransactionId: payStackTransactionId,
    },
    useWithWalletBalance: useWithWalletBalance,
    guestPhoneNumber: phoneNumber,
  };

  return apiClient.post("/reservation/guest/book", payload);
};
const createReservationOffline = (
  restaurantId,
  floorplanId,
  date,
  time,
  firstName,
  lastName,
  phoneNumber,
  email,
  guestNum,

  occasionCelebrating,
  groupMemberDietaryRestriction,
  specialRequest,
  amount,
  payStackTransactionId,
  noPayment
) => {
  const payload = {
    restaurantId: restaurantId,
    floorplanId : floorplanId,
    date: date,
    time: time,
    firstName,
    lastName,
    phoneNumber,
    email,
    guestNum,
    wantToGetNewsAndUpdates: true,
    wantToGetEmailsAndOffersNotification: true,
      reservationSource:"Website",
    planGuestVisit: {
      occasionCelebrating,
      groupMemberDietaryRestriction,
      specialRequest,
    },
    bookingPayment: {
      amount,
      paymentMethod: noPayment ? "NoPayment" :"Card",
      payStackTransactionId,
    },
  };
  // console.log(payload, "Offline")

  return apiClient.post("/reservation/guest/book_offline", payload);
};

const getReservationCost = (guestSize, restaurantId, restaurantName) => {
  return apiClient.get(
    `reservation/guest/reservation_cost/${guestSize}?restaurantId=${restaurantId}&restaurantName=${restaurantName}`
  );
};

const getBookedReservationDetails = (id) => {
  return apiClient.get(`/reservation/book/get/${id}`);
};

const getCanceledReservation = () => {
  return apiClient.get(`/reservation/guest/get_cancelled_booking`);
};

// RESERVATIONS .......

const getUpcoming = () => {
  return apiClient.get("/reservation/guest/get_incoming");
};
const getPast = () => {
  return apiClient.get("/reservation/guest/past");
};

const submitRating = (
  foodRating,
  valueForMoneyRating,
  noiseLevelsRating,
  serviceRating,
  ambienceRating,
  overallRating,
  comment,
  restaurantId,
  bookingId,
  guestId
) => {
  const payload = {
 foodRating,
  valueForMoneyRating,
  noiseLevelsRating,
  serviceRating,
  ambienceRating,
  overallRating,
  comment,
    restaurantId,
    bookingId,
    guestId,
    isAppUser: false,
  };

  return apiClient.post(`guestservice/add_restaurant_review`, payload);
};

const updateReservation = (
  reservationId,
  partySize,
  date,
  time,
  specialRequest,
  groupMemberDietaryRestriction
) => {
  const payload = {
    reservationId,
    partySize,
    date,
    time,
    specialRequest,
    groupMemberDietaryRestriction,
  };

  return apiClient.put(`/reservation/guest/manage`, payload);
};

// MANAGE RESERVATION

const getOfflineBooking = (id) => {
  return apiClient.get(`reservation/book/offline/${id}`);
};

const cancelReservation = (id) => {
  return apiClient.post(`reservation/book/cancel/${id}`);
};

const manageReservationOffline = (
  reservationId,
  partySize,
  date,
  time,
  specialRequest,
  groupMemberDietaryRestriction,
  firstName,
  lastName,
  email,
  phoneNumber,
  occasionCelebrating
) => {
  const payload = {
    reservationId,
    partySize,
    date,
    time,
    specialRequest,
    groupMemberDietaryRestriction,
    firstName,
    lastName,
    email,
    phoneNumber,
    occasionCelebrating,
  };
  return apiClient.put("reservation/guest/offline/manage", payload);
};

// RESERVATION WIDGET

const getRestaurantDetails = (name) => {
  return apiClient.get(`/guestservice/restaurant/${name}`);
};

const bookReservationZero = (restaurantId, date, time, guestId, ) =>{
const payload = {

}
}

const getAllEvents = () =>{
  return apiClient.get("/restaurantevent/all")
}

// Export all the API functions
export {
  loginUser,
  loginGoogle,
  createUser,
  getAllFeaturedRestaurants,
  getBestRestaurants,
  getUserProfile,
  editUserProfile,
  uploadUserImage,
  changePassword,
  getTransactions,
  getWallet,
  getSingleRestaurant,
  getOpeningHours,
  getReservationCost,
  createReservation,
  getBookedReservationDetails,
  filterRestaurant,
  findRestaurant,
  getUpcoming,
  getPast,
  submitRating,
  getOfflineBooking,
  manageReservationOffline,
  addToFavourite,
  getRestaurantDetails,
  cancelReservation,
  verifiyOtp,
  resendOtp,
  updateReservation,
  getCanceledReservation,
  createReservationOffline,
  getAllEvents,
  createReservationDiner
};
