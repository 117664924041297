import { Button , Box, Typography, Grid, IconButton} from '@mui/material';
import React, { useState } from 'react';
import CustomInput from '../Custom/CustomInput';
import { MdRemoveCircleOutline } from "react-icons/md";

const GuestForm = ({onSubmit}) => {
  const [guests, setGuests] = useState([]);

  const handleInputChange = (index, event) => {
    const values = [...guests];
    values[index][event.target.name] = event.target.value;
    setGuests(values);
  };

  const handleAddGuest = () => {
    setGuests([...guests, { fullName: '', phoneNumber: '' }]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(guests);
    onSubmit(guests)
  };

    const handleDeleteGuest = (index) => {
    const values = [...guests];
    values.splice(index, 1);
    setGuests(values);
  };

    const isAddButtonDisabled = () => {
      if(guests.length > 0 ){
    const lastGuest = guests[guests.length - 1];
    return !lastGuest.fullName || !lastGuest.phoneNumber;
      }

  };

  return (
<>
          <Button
          disabled={isAddButtonDisabled()}
          onClick={handleAddGuest}
                    sx={{ borderRadius: "10px", py: 1.5 , fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'}}}
                    fullWidth
                    variant="contained"
                  >
                    Add Guest(s)
                  </Button>


      <form onSubmit={handleSubmit}>
      {guests.map((guest, index) => (
        <Box sx={{border:'1px solid #d7d7d7', p:2, borderRadius:'10px', mt:2}} key={index} >
        <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <Typography color="primary" sx={{fontWeight:500, fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'}}}>Guest {index + 1}</Typography>

          <IconButton onClick={() => handleDeleteGuest(index)} >
            <MdRemoveCircleOutline style={{color:'#BC172F', }} />
          </IconButton>
        </Box>
        <Grid  container spacing={2} style={{ marginBottom: '10px' }}>

    <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInput
            fullWidth
            type="text"
            name="fullName"
            placeholder="Full Name"
            value={guest.fullName}
            onChange={(event) => handleInputChange(index, event)}
            style={{ marginRight: '10px' }}
          />
    </Grid>
     <Grid item lg={6} md={6} sm={12} xs={12} >
              <CustomInput
fullWidth
            type="text"
            name="phoneNumber"
            placeholder="Phone Number"
            value={guest.phoneNumber}
            onChange={(event) => handleInputChange(index, event)}
            style={{ marginRight: '10px' }}
          />
     </Grid>
        </Grid>
        </Box>
      ))}
{
  guests?.length > 0 && (
<>
          <Button    disabled={isAddButtonDisabled()} type="Button" onClick={handleAddGuest} sx={{mt:2, fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'}}}>
        Add Another Guest
      </Button>
      <Button type="submit" sx={{mt:2, fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'}}}>Submit</Button>
</>
  )
}
    </form>

</>



  );
};

export default GuestForm;
